$('.single-item').slick({
    prevArrow: $('.prev'),
nextArrow: $('.next'),
dots:true
});
$('.slick-product').each(function() {
    $(this).slick({
        dots: false,
        prevArrow: $(this).parent().find('.prev'),
        nextArrow: $(this).parent().find('.next'),
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
      });
});
$('.brand-slider').each(function() {
    $(this).slick({
        dots: false,
        prevArrow: $(this).parent().find('.prev'),
        nextArrow: $(this).parent().find('.next'),
        speed: 300,
        slidesToShow: 7,
        slidesToScroll: 1,
      });
});

$('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    autoheight:true,
    asNavFor: '.slider-nav'
});

$('.slider-nav').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 5
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2
            }
        }
    ]
});